import React from 'react'

import { Content } from '../components/Content'
import { PageList } from '../components/PageList'
import { Section } from '../components/Section'

const Page = () => (
  <Content heading="Palautteet">
    <Section>
      <PageList />
    </Section>
  </Content>
)

export default Page
